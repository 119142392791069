<template>
  <div class="system-page page">
    <loading :active.sync="isLoading" loader="dots"></loading>

    <div class="function-card">
      <button
        type="button"
        class="btn btn-primary btn-sm"
        @click="openCreateModal('main')"
      >
        新增
      </button>
    </div>

    <div class="yahome-table-wrap">
      <VueTreeGrid
        :key="treeKey"
        :columns="columns"
        :tree="tree"
        @expand="onRowExpanded"
      >
        <template slot="sort--row-cell" slot-scope="scope">
          <div>
            <button
              v-if="scope.row._index !== 0"
              class="btn btn btn-outline-secondary btn-sm"
              @click="changeMenuSort(scope.row, 'up')"
            >
              <i class="fas fa-long-arrow-alt-up"></i>
            </button>

            <span v-if="scope.row._level === 1">
              <button
                v-if="scope.row._index !== tree.length - 1"
                class="btn btn btn-outline-secondary btn-sm"
                @click="changeMenuSort(scope.row, 'down')"
              >
                <i class="fas fa-long-arrow-alt-down"></i>
              </button>
            </span>
            <span v-if="scope.row._level === 2">
              <button
                v-if="scope.row._index !== scope.row.childrenLength - 1"
                class="btn btn btn-outline-secondary btn-sm"
                @click="changeMenuSort(scope.row, 'down')"
              >
                <i class="fas fa-long-arrow-alt-down"></i>
              </button>
            </span>
          </div>
        </template>
        <template slot="permission--row-cell">
          <button
            type="button"
            class="btn btn-primary btn-sm"
            @click="openPermissionModal"
          >
            權限
          </button>
        </template>
        <template slot="action--row-cell" slot-scope="scope">
          <button
            v-if="scope.row._level === 1"
            type="button"
            class="btn btn-primary btn-sm"
            @click="openCreateModal('child', scope.row)"
          >
            新增
          </button>
          <button
            type="button"
            class="btn btn-info btn-sm"
            @click="openEditModal(scope.row)"
          >
            編輯
          </button>
           <!-- v-if="scope.row.ExternalLink && scope.row._childrenLength === 0" -->
          <button
            v-if="scope.row._childrenLength === 0"
            type="button"
            class="btn btn-danger btn-sm"
            @click="openDelModal(scope.row)"
          >
            刪除
          </button>
        </template>
      </VueTreeGrid>
    </div>

    <!--權限 Modal-->
    <div
      id="permissionModal"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      data-backdrop="static"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <form @reset.prevent="reset">
            <div class="modal-header">
              <span>權限管理</span>
              <!-- <button type="reset" class="close" @click="close">
                <span style="color: #222">&times;</span>
              </button> -->
            </div>
            <div class="modal-body">
              <el-transfer
                filterable
                :filter-method="filterMethod"
                filter-placeholder="請輸入姓名或員編"
                v-model="value"
                :data="data"
                :titles="['未選取人員', '已選取人員']"
              ></el-transfer>
            </div>
            <div class="modal-footer">
              <button
                type="reset"
                class="btn btn-secondary"
                aria-label="關閉"
                data-dismiss="modal"
              >
                取消
              </button>
              <button type="reset" class="btn btn-success">儲存</button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!--新增 Modal-->
    <div
      id="createModal"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      data-backdrop="static"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <ValidationObserver
          ref="createMenu"
          class="modal-content"
          v-slot="{ handleSubmit, reset }"
        >
          <form @reset.prevent="reset">
            <div class="modal-header">
              <span v-if="!ParentName">新增主選單</span>
              <span v-else>新增子選單</span>
            </div>
            <div class="modal-body">
              <div v-if="ParentName" class="form-group">
                <h5 style="margin-bottom: 25px">
                  <span style="font-size: 16px">上層選單：</span>
                  <span class="badge badge-info">
                    {{ ParentName }}
                  </span>
                </h5>
              </div>

              <div v-if="!ParentName" class="form-group">
                <ValidationProvider
                  name="icon"
                  rules="required"
                  tag="div"
                  v-slot="{ errors }"
                >
                  <label><span class="mark">*</span>icon</label>
                  <multiselect
                    v-model="Icon"
                    placeholder="請選擇 icon"
                    label="title"
                    track-by="title"
                    :options="IconList"
                    :show-labels="false"
                  >
                    <template slot="singleLabel" slot-scope="props">
                      <img
                        style="height: 25px"
                        :src="props.option.img"
                        alt="icon"
                      />
                    </template>
                    <template slot="option" slot-scope="props">
                      <img
                        style="height: 30px"
                        :src="props.option.img"
                        alt="icon"
                      />
                    </template>
                  </multiselect>
                  <span v-if="errors[0]" class="text-danger">
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>

              <div class="form-group">
                <ValidationProvider
                  name="選單名稱"
                  rules="required"
                  tag="div"
                  v-slot="{ errors }"
                >
                  <label><span class="mark">*</span>選單名稱</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="請輸入選單名稱"
                    @compositionstart="is_composing = true"
                    @compositionend="is_composing = false"
                    v-model="createTemp.MenuName"
                  />
                  <span v-if="errors[0] && !is_composing" class="text-danger">{{
                    errors[0]
                  }}</span>
                </ValidationProvider>
              </div>

              <div v-if="ParentName" class="form-group">
                <ValidationProvider
                  name="選單連結"
                  rules="required"
                  tag="div"
                  v-slot="{ errors }"
                >
                  <label><span class="mark">*</span>選單連結</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="請輸入選單連結"
                    v-model="createTemp.Link"
                  />
                  <span class="text-danger">{{ errors[0] }} </span>
                </ValidationProvider>
              </div>

              <div v-if="!ParentName" class="form-group">
                <label><span class="mark">*</span>開新視窗</label>
                <select class="form-control" v-model="createTemp.NewWindow">
                  <option :value="true">是</option>
                  <option :value="false">否</option>
                </select>
              </div>

              <div v-if="ParentName" class="form-group">
                <label><span class="mark">*</span>外部連結</label>
                <select class="form-control" v-model="createTemp.ExternalLink">
                  <option :value="true">是</option>
                  <option :value="false">否</option>
                </select>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="reset"
                class="btn btn-secondary"
                aria-label="關閉"
                data-dismiss="modal"
              >
                取消
              </button>
              <button
                type="button"
                class="btn btn-success"
                @click="handleSubmit(createMenu)"
              >
                儲存
              </button>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>

    <!--編輯 Modal-->
    <div
      id="editModal"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      data-backdrop="static"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <ValidationObserver
          ref="editMenu"
          class="modal-content"
          v-slot="{ handleSubmit, reset }"
        >
          <form @reset.prevent="reset">
            <div class="modal-header">
              <span v-if="!ParentName">編輯主選單</span>
              <span v-else>編輯子選單</span>
            </div>
            <div class="modal-body">
              <div v-if="!ParentName" class="form-group">
                <ValidationProvider
                  name="icon"
                  rules="required"
                  tag="div"
                  v-slot="{ errors }"
                >
                  <label><span class="mark">*</span>icon</label>
                  <multiselect
                    v-model="Icon"
                    placeholder="請選擇 icon"
                    label="title"
                    track-by="title"
                    :options="IconList"
                    :show-labels="false"
                  >
                    <template slot="singleLabel" slot-scope="props">
                      <img
                        style="height: 25px"
                        :src="props.option.img"
                        alt="icon"
                      />
                    </template>
                    <template slot="option" slot-scope="props">
                      <img
                        style="height: 30px"
                        :src="props.option.img"
                        alt="icon"
                      />
                    </template>
                  </multiselect>
                  <span v-if="errors[0]" class="text-danger">
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>

              <div class="form-group">
                <ValidationProvider
                  name="選單名稱"
                  rules="required"
                  tag="div"
                  v-slot="{ errors }"
                >
                  <label
                    ><span class="mark">*</span>選單名稱
                    <span class="mark">(名稱不可重複)</span></label
                  >
                  <input
                    type="text"
                    class="form-control"
                    @compositionstart="is_composing = true"
                    @compositionend="is_composing = false"
                    v-model="editTemp.MenuName"
                  />
                  <span v-if="errors[0] && !is_composing" class="text-danger">{{
                    errors[0]
                  }}</span>
                </ValidationProvider>
              </div>
              <div v-if="editLink" class="form-group">
                <ValidationProvider
                  name="選單連結"
                  rules="required"
                  tag="div"
                  v-slot="{ errors }"
                >
                  <label><span class="mark">*</span>選單連結</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="editTemp.Link"
                  />
                  <span class="text-danger">{{ errors[0] }} </span>
                </ValidationProvider>
              </div>

              <div class="form-group">
                <label>開新視窗</label>
                <select class="form-control" v-model="editTemp.NewWindow">
                  <option :value="true">是</option>
                  <option :value="false">否</option>
                </select>
              </div>

              <div v-if="isHref" class="form-group">
                <label><span class="mark">*</span>外部連結</label>
                <select class="form-control" v-model="editTemp.ExternalLink">
                  <option :value="true">是</option>
                  <option :value="false">否</option>
                </select>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="reset"
                class="btn btn-secondary"
                aria-label="關閉"
                data-dismiss="modal"
              >
                取消
              </button>
              <button
                type="button"
                class="btn btn-success"
                @click="handleSubmit(editMenu)"
              >
                儲存
              </button>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>

    <!-- 刪除選單 Modal -->
    <div
      id="delMenu"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      data-backdrop="static"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">刪除選單</div>
          <div class="modal-body">
            確認刪除
            <span style="font-weight: bolder; color: red">
              {{ delelteTemp.MenuName }}</span
            >
            ?
          </div>
          <div class="modal-footer">
            <button
              type="reset"
              class="btn btn-secondary"
              aria-label="關閉"
              data-dismiss="modal"
            >
              取消
            </button>
            <button
              type="button"
              class="btn btn-danger"
              data-dismiss="modal"
              @click="delMenu(delelteTemp)"
            >
              刪除
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* global $ */
import VueTreeGrid from "@reallife/vue-tree-grid";

export default {
  name: "Menu",
  data() {
    const generateData = () => {
      const data = [];
      const employee = [
        "黃一明",
        "黃二明",
        "黃三明",
        "黃四明",
        "黃五明",
        "黃六明",
        "黃七明",
      ];
      const pinyin = [
        "黃一明-J001",
        "黃二明-J002",
        "黃三明-J003",
        "黃四明-J004",
        "黃五明-J005",
        "黃六明-J006",
        "黃七明-J007",
      ];
      employee.forEach((name, index) => {
        data.push({
          label: name,
          key: index,
          pinyin: pinyin[index],
        });
      });
      return data;
    };

    return {
      treeLength: 0,
      is_composing: false, // compositionstart& compositionend

      // icon
      Icon: {},
      // 選單icon 提供
      IconList: [
        {
          title: "settings-outline",
          img: require("@/assets/icons/eva/settings-outline.png"),
        },
        {
          title: "options-2-outline",
          img: require("@/assets/icons/eva/options-2-outline.png"),
        },
        {
          title: "file-text-outline",
          img: require("@/assets/icons/eva/file-text-outline.png"),
        },
        {
          title: "person-add-outline",
          img: require("@/assets/icons/eva/person-add-outline.png"),
        },
        {
          title: "people-outline",
          img: require("@/assets/icons/eva/people-outline.png"),
        },
        {
          title: "pie-chart-outline",
          img: require("@/assets/icons/eva/pie-chart-outline.png"),
        },
        {
          title: "pantone-outline",
          img: require("@/assets/icons/eva/pantone-outline.png"),
        },
        {
          title: "link-2-outline",
          img: require("@/assets/icons/eva/link-2-outline.png"),
        },
        {
          title: "clipboard-outline",
          img: require("@/assets/icons/eva/clipboard-outline.png"),
        },
        {
          title: "share-outline",
          img: require("@/assets/icons/eva/share-outline.png"),
        },
        {
          title: "image-outline",
          img: require("@/assets/icons/eva/image-outline.png"),
        },
        {
          title: "email-outline",
          img: require("@/assets/icons/eva/email-outline.png"),
        },
        {
          title: "inbox-outline",
          img: require("@/assets/icons/eva/inbox-outline.png"),
        },
        {
          title: "calendar-outline",
          img: require("@/assets/icons/eva/calendar-outline.png"),
        },
        {
          title: "folder-outline",
          img: require("@/assets/icons/eva/folder-outline.png"),
        },
        {
          title: "message-square-outline",
          img: require("@/assets/icons/eva/message-square-outline.png"),
        },
        {
          title: "eye-outline",
          img: require("@/assets/icons/eva/eye-outline.png"),
        },
        {
          title: "printer-outline",
          img: require("@/assets/icons/eva/printer-outline.png"),
        },
        {
          title: "cloud-download-outline",
          img: require("@/assets/icons/eva/cloud-download-outline.png"),
        },
        {
          title: "facebook-outline",
          img: require("@/assets/icons/eva/facebook-outline.png"),
        },
      ],

      ParentName: null,

      createTemp: {},

      editTemp: {},
      editLink: false, // 選單連結是否可編輯
      isHref: false, // 外部連結是否可編輯

      delelteTemp: {},

      treeKey: 0,
      columns: [
        {
          type: "prop",
          prop: "sort",
          width: "5%",
        },
        {
          prop: "MenuName",
          label: "選單名稱",
          width: "25%",
        },
        {
          prop: "Link",
          label: "選單連結",
          width: "25%",
        },
        // {
        //   type: "prop",
        //   prop: "permission",
        //   label: "權限",
        //   width: "25%",
        // },
        {
          type: "prop",
          prop: "action",
          label: "",
          width: "5%",
        },
      ],
      tree: [],
      data: generateData(),
      value: [],
      filterMethod(query, item) {
        return item.pinyin.indexOf(query) > -1;
      },
      treePath: null,
    };
  },
  components: {
    VueTreeGrid,
  },
  methods: {
    onRowExpanded(row) {
      this.treePath = Number(row._path);
    },
    // 取得選單 API
    getMenu() {
      const vm = this;
      const api = `${process.env.VUE_APP_API}/auth/menu`;
      vm.$store.dispatch("updateLoading", true);
      let tree = [];
      vm.$http
        .get(api)
        .then((response) => {
          if (response.status === 200) {
            
            
            vm.$store.dispatch("updateLoading", false);
            vm.treeKey++;
            response.data.forEach((item) => {
              let data = {
                ...item,
                children: item.Children,
                _isExpanded: false,
              };
              data.children.forEach((item) => {
                item.childrenLength = data.children.length;
              });
              tree.push(data);
            });
            vm.tree = tree;
            if (vm.treePath !== null) {
              vm.tree[vm.treePath]._isExpanded = true;
            }
            vm.$store.commit("EDITMENU", false);
          }
        })
        .catch((error) => {
          vm.$store.dispatch("updateLoading", false);
          vm.$bus.$emit("message: push", error.response.data, "danger");
        });
    },

    // 改變選單排序 API
    changeMenuSort(item, type) {
      const vm = this;
      const api = `${process.env.VUE_APP_API}/auth/menu/sort`;

      const data = {
        MenuGuid: item.Guid,
        Turn: type,
      };

      vm.$store.dispatch("updateLoading", true);
      vm.$http
        .post(api, data)
        .then((response) => {
          if (response.status === 200) {
            vm.$store.commit("EDITMENU", true);
            vm.$store.dispatch("updateLoading", false);
            vm.$bus.$emit("message: push", "選單順序調整成功");
            vm.getMenu();
          }
        })
        .catch((error) => {
          vm.$store.dispatch("updateLoading", false);
          vm.$bus.$emit("message: push", error.response.data, "danger");
        });
    },

    // 新增選單 Modal
    openCreateModal(type, item) {
      this.ParentName = null;
      this.createTemp = {};
      this.Icon = null;
      if (type === "child") {
        this.ParentName = item.MenuName;
        this.createTemp.ParentMenuGuid = item.Guid;
        this.$set(this.createTemp, "NewWindow", false);
        this.$set(this.createTemp, "ExternalLink", true);
      } else {
        this.createTemp.ParentMenuGuid = null;
        this.$set(this.createTemp, "Link", "--");
        this.$set(this.createTemp, "NewWindow", false);
        this.$set(this.createTemp, "ExternalLink", true);
      }
      this.$refs.createMenu.reset();
      $("#createModal").modal("show");
    },

    // 新增選單 API
    createMenu() {
      const vm = this;
      const api = `${process.env.VUE_APP_API}/auth/menu`;

      let message = "子選單新增成功";
      let icon = null;

      if (vm.createTemp.ParentMenuGuid === null) {
        icon = vm.Icon.title;
        message = "主選單新增成功";
      }

      const data = {
        ...this.createTemp,
        Icon: icon,
        //CreateUser: vm.$cookies.get("userName"),
        CreateUser: "金城武",
      };

      $("#createModal").modal("hide");
      vm.$store.dispatch("updateLoading", true);
      vm.$http
        .post(api, data)
        .then((response) => {
          if (response.status === 200) {
            vm.$store.commit("EDITMENU", true);
            vm.$store.dispatch("updateLoading", false);
            vm.$bus.$emit("message: push", message);
            vm.getMenu();
          }
        })
        .catch((error) => {
          vm.$store.dispatch("updateLoading", false);
          vm.$bus.$emit("message: push", error.response.data, "danger");
        });
    },

    // 編輯選單 Modal
    openEditModal(item) {
      this.ParentName = null; // 上層選單名稱

      if (item.ParentMenuGuid) {
        if (item.ExternalLink) {
          this.editLink = true; // 選單連結是否可編輯
          this.isHref = true; // 外部連結是否可編輯
        }
        this.ParentName = item.ParentMenuGuid;
      } else {
        this.Icon = {
          title: item.Icon,
          img: require(`@/assets/icons/eva/${item.Icon}.png`),
        };
        this.editLink = false;
        this.isHref = false;
      }

      this.editTemp = { ...item };
      this.$refs.editMenu.reset();
      $("#editModal").modal("show");
    },

    // 編輯選單 API
    editMenu() {
      const vm = this;
      const api = `${process.env.VUE_APP_API}/auth/menu`;

      let icon = null;
      if (vm.editTemp.ParentMenuGuid === null) {
        icon = vm.Icon.title;
      }

      const data = {
        ...this.editTemp,
        Icon: icon,
        //CreateUser: vm.$cookies.get("userName"),
        CreateUser: "金城武",
      };

      
      $("#editModal").modal("hide");
      vm.$store.dispatch("updateLoading", true);
      vm.$http
        .put(api, data)
        .then((response) => {
          if (response.status === 200) {
            vm.$store.commit("EDITMENU", true);
            vm.$store.dispatch("updateLoading", false);
            vm.$bus.$emit("message: push", "選單編輯成功");
            vm.getMenu();
          }
        })
        .catch((error) => {
          vm.$store.dispatch("updateLoading", false);
          vm.$bus.$emit("message: push", error.response.data, "danger");
        });
    },

    // 刪除選單 Modal
    openDelModal(item) {
      this.delelteTemp = { ...item };
      $("#delMenu").modal("show");
    },

    // 刪除選單 API
    delMenu(item) {
      const vm = this;
      const api = `${process.env.VUE_APP_API}/auth/menu/${item.Guid}`;

      vm.$store.dispatch("updateLoading", true);
      vm.$http
        .delete(api)
        .then((response) => {
          if (response.status === 200) {
            vm.$store.dispatch("updateLoading", false);
            vm.$bus.$emit("message: push", "刪除成功");
            vm.$store.commit("EDITMENU", true);
            vm.getMenu();
          }
        })
        .catch((error) => {
          vm.$store.dispatch("updateLoading", false);
          vm.$bus.$emit("message: push", error.response.data, "danger");
        });
    },

    // 權限 Modal
    openPermissionModal() {
      $("#permissionModal").modal("show");
    },
  },
  computed: {
    isLoading() {
      return this.$store.state.isLoading;
    },
  },

  created() {
    this.getMenu();
  },
};
</script>

<style lang="scss">
i.tree-grid__expand-icon {
  background-color: #4062c2 !important;
  border-radius: 50% !important;
  color: #fff;
  line-height: 17px !important;
  width: 20px !important;
  height: 20px !important;
  margin-left: -20px;

  &.tree-grid__expand-icon--expanded {
    background-color: #e55353 !important;
  }
}

.tree-grid__body-cell {
  .btn {
    margin-right: 5px;
  }
}
</style>
